<template>
  <div class="login-wrap-tol">
    <Top />
    <div class="box">
      <div class="flex row-center pt92">
        <div class="tab_1" @click="goNowPanle">设<br>备<br>实<br>时<br>看<br>板</div>
        <div class="tab_2" @click="godata">数<br>据<br>看<br>板</div>
        <div class="tab_3" @click="godevice">设<br>备<br>管<br>理</div>
      </div>
      <div class="login_out flex col-center row-center mt40" @click="exit">
        <img src="../../assets/img/exit.png" class="w40 h43 mr12" />
        退出
      </div>
    </div>

    <!-- <div class="login-mid">
      <div class="data" @click="godata">
        <span class="span1">数据看板</span>
        <span class="span2">DATA BOARD</span>
      </div>
      <div class="device" @click="godevice">
        <span class="span1">设备管理</span>
        <span class="span2">DEVICE MANAGEMENT</span>
      </div>
      <div class="exit" @click="exit">
        <img src="../../assets/img/exit.png" alt="EXIT">
        <div class="exitbut">退出</div>
      </div>
    </div> -->
    <!-- <num1 /> -->
  </div>
</template>

<script>
import "./contorlpage.css";
import "../Login/Login.css"
import Top from '../../components/top.vue'
import { getFuRui, getFuRuiShi } from '@/api/Robot'
// import num1 from '@/components/num1/index.vue'
export default {
  name: 'contorlpage',
  components: {
    Top,
    // num1
  },
  created () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (sessionStorage.getItem('clientId') == 100) {
      getFuRui()
      getFuRuiShi()
      this.timer = setInterval(() => {
        getFuRuiShi()
      }, 179000)
    }
  },
  beforeUnmount () {
    //清除定时器
    clearInterval(this.timer)
    this.timer = null
  },
  data () {
    return {
      lookType: JSON.parse(sessionStorage.getItem('lookType')),
      LoginInfoKey: "toilet-auto-login",
      timer: null
    };
  },
  methods: {
    exit () {
      localStorage.removeItem('show_token')
      localStorage.removeItem(this.LoginInfoKey)
      this.$store.commit('changetitle', '')
      sessionStorage.removeItem('a_title')
      this.$router.replace('login')
    },
    godata () {
      let pushUrl = ''
      let i1 = this.lookType.indexOf(0)
      let i = this.lookType.indexOf(5)
      if (i > -1) {
        this.lookType[i] = 1
        this.lookType = [...new Set(this.lookType)]
      }
      if (i1 > -1) {
        this.lookType[i1] = 1
        this.lookType = [...new Set(this.lookType)]
      }
      if (this.lookType && this.lookType.length == 1) {
        if (this.lookType.includes(1)||this.lookType.includes(6)) {
          pushUrl = 'Sterilizer'
        } else if (this.lookType.includes(2)) {
          pushUrl = 'OneRobot'
        } else if (this.lookType.includes(3)) {
          pushUrl = 'Robot'
        }
        // this.lookType.includes(1) ? pushUrl = 'XiaodujiLook' : pushUrl = 'People'
      } else {
        if (this.lookType.includes(1) && this.lookType.includes(2) && this.lookType.includes(3)) {
          pushUrl = 'AllPanle'
        }
        else if (this.lookType.includes(1) && this.lookType.includes(2)) {
          pushUrl = 'Whole'
        } else if (this.lookType.includes(1) && this.lookType.includes(3)) {
          pushUrl = 'SterilizerRobot'
        } else if (this.lookType.includes(2) && this.lookType.includes(3)) {
          pushUrl = 'twoRobot'
        } else if (this.lookType.includes(1) && this.lookType.includes(6)) {
          pushUrl = 'Sterilizer'
        }
      }
      this.$router.push(pushUrl)
    },
    godevice () {
      this.$router.push('list2')
    },
    goNowPanle () {
      this.$router.push('DevicesNowTime')
    }
  },
};
</script>


<style lang='scss' scoped>
.login-wrap-tol {
  position: relative;
  width: 100%;
  height: 100vh !important;
  background-image: linear-gradient(
    180deg,
    #011728 0%,
    #142c3b 66%,
    #000000 100%
  ) !important;
  /* padding-top: 25px; */
}

.data,
.device {
  width: 519px;
  height: 146px;
  background: rgba(10, 137, 133, 0.3);
  /* opacity: 0.3; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
}
.device {
  margin-top: 30px;
}
.span1 {
  font-size: 32px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
}
.span2 {
  font-size: 18px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #ffffff;
  margin-top: 5px;
}
.exit {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 195px;
  width: 519px;
  height: 92px;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.35 cubic-bezier(0.075, 0.82, 0.165, 1);
}
.exit:hover {
  background: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.25);
}
.exit img {
  width: 40px;
  height: 44px;
}
.exitbut {
  margin-left: 10px;
  font-size: 32px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.1);
}
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
}
.tab_1,
.tab_2,
.tab_3 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 622px;
  font-size: 53px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  cursor: pointer;
}
.tab_1 {
  width: 360px;
  margin-right: 40px;
  background: linear-gradient(
    to right,
    rgba(10, 137, 133, 0.6),
    rgba(14, 37, 53, 0)
  );
}
.tab_2 {
  width: 520px;
  background: rgba(10, 137, 133, 0.3);
}
.tab_3 {
  width: 360px;
  margin-left: 40px;
  background: linear-gradient(
    to left,
    rgba(10, 137, 133, 0.6),
    rgba(14, 37, 53, 0)
  );
}
.login_out {
  margin: 0 auto;
  width: 520px;
  height: 92px;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  font-size: 32px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  cursor: pointer;
}
</style>